<script>
export default {
    name: "userList-task",
    data(){
      return {
          lang:this.$lang.getLang('user','userListTask'),
          user:{id:-1,username:'',},
          view:false,
          version:Number(this.$cookie.getCookie('version')),
          // version:2,
          taskOptions:{taskTime:'',money_int:1,money_time:''},
          timeFormat:'YYYY-MM-DD HH:mm:ss',
      }
    },
    methods:{
        openTaskView(id,username){
            this.user = {id,username}
            this.taskOptions = {taskTime:'',money_int:1,money_time:''};
            this.view = true;
            console.log(this.lang)
        },
        onSubmit(){
            console.log('taskOptions',this.taskOptions)
            let value = this.version === 1 ? this.taskOptions.money_int : this.taskOptions.money_time;
            let taskTime = this.taskOptions.taskTime;
            if (!value || !taskTime){
                this.$message.error(this.lang.onSubmit.error);
                return;
            }
            let postData = {
                userId:this.user.id,
                time:taskTime,
                value,
            };
            this.$api.post('User.Task/setUserMoneyTask',postData)
                .then(res => {
                    console.log('res',res)
                    let code = res?.data?.code;
                    // let msg = res?.data?.msg;
                    if (code !== 100){
                        let errCode = code ? Number(code) : 'default';
                        this.$alert('ErrMsg:' + this.lang.setUserMoneyTask[errCode],'ErrorCode:' + code,{type:'error'})
                        return;
                    }
                    this.$alert(this.lang.setUserMoneyTask['100'],'OK',{type:'success'}).finally(() => this.view = false);
                })
            console.log('post',postData)
        },
        getUserTask(){
            this.$api.post('User.Task/getUserTask',{userId:this.user.id})
                .then(res => {
                    // console.log('res',res)
                    let code = res?.data?.code;
                    // let msg = res?.data?.msg;
                    if (code !== 100){
                        let errCode = code ? Number(code) : 'default';
                        this.$alert('ErrMsg:' + this.lang.getUserTask[errCode],'ErrorCode:' + code,{type:'error'})
                        return;
                    }
                    let {task_time,create_time,value,status,} = res?.data?.data;
                    let msg =
                        this.lang.getUserTask.msg.taskTime +
                        task_time + '<br>' +

                        this.lang.getUserTask.msg.createTime +
                        create_time + '<br>' +

                        this.lang.getUserTask.msg.taskValue +
                        value + '<br>' +

                        this.lang.getUserTask.msg.taskStatus +
                        this.lang.getUserTask.status[Number(status)] ?? this.lang.getUserTask.status['default']
                    ;
                    this.$alert(msg,'task',{
                        dangerouslyUseHTMLString: true,
                    });
                })
        },
    }
}
</script>

<template>
    <el-dialog v-model="view" :title="'Id:' + user.id">

        <el-form  label-width="auth">
            <el-form-item :label="this.lang.html.elformitem.username">
                <span style="color: #F56C6C">{{user.username}}</span>
            </el-form-item>
            <el-form-item :label="this.lang.html.elformitem.taskTime">
                <el-date-picker
                    v-model="taskOptions.taskTime"
                    type="datetime"
                    :format=timeFormat
                    :value-format=timeFormat
                />
            </el-form-item>
            <el-form-item
                :label="this.lang.html.elformitem.money + '[' + (version === 1 ? this.lang.html.elformitem.ver[1] : this.lang.html.elformitem.ver[2]) + ']'"
            >
<!--                次数版-->
                <el-input-number
                    v-if="version === 1"
                    v-model="taskOptions.money_int"
                    :min="1"
                    :max="9999"
                />
<!--                月费版-->
                <el-date-picker
                    v-else
                    v-model="taskOptions.money_time"
                    type="datetime"
                    :format=timeFormat
                    :value-format=timeFormat
                />
            </el-form-item>
            <el-button type="warning" @click="() => getUserTask()">
                {{this.lang.html.getUserTask}}
            </el-button>
        </el-form>

        <template #footer>
            <el-button @click="() => view = false">
                {{this.lang.html.footer.cancel}}
            </el-button>
            <el-button type="primary" @click="onSubmit">
                {{this.lang.html.footer.submit}}
            </el-button>
        </template>
    </el-dialog>
</template>

<style scoped>

</style>