<template>
    <el-dialog
        v-model="userInfoShow"
        title='User Info'
        width="30%"
    >
    <el-form v-loading=loading size="small">
        <el-form-item :label="lang['html.el-form-item.label.agent']">
            <span>{{info.agent}}</span>
        </el-form-item>

        <el-form-item :label="lang['html.el-form-item.label.username']">
            <span>{{info.username}}</span>
        </el-form-item>

        <el-form-item :label="lang['html.el-form-item.label.password']">
            <el-input v-model="submitData.password" />
        </el-form-item>

        <el-form-item :label="lang['html.el-form-item.label.name']">
            <el-input v-model="submitData.name" />
        </el-form-item>

        <el-form-item :label="lang['html.el-form-item.label.nickname']">
            <el-input v-model="submitData.nickname" />
        </el-form-item>

        <el-form-item :label="lang['html.el-form-item.label.contact']">
            <el-input v-model="submitData.contact" />
        </el-form-item>

        <el-form-item :label="lang['html.el-form-item.label.status']">
            <el-radio-group v-model="submitData.status">
                <el-radio :label="1">{{lang['html.el-radio-group.status.1']}}</el-radio>
                <el-radio :label="0">{{lang['html.el-radio-group.status.0']}}</el-radio>
            </el-radio-group>
        </el-form-item>

        <el-form-item :label="lang['html.el-form-item.label.time']">
            <span>
                <el-tag>
                    {{lang['html.el-tag.login_time']}}:{{info.login_time}}
                </el-tag>
                <el-tag style="margin-left: 10px" type="info">
                    {{lang['html.el-tag.reg_time']}}:{{info.reg_time}}
                </el-tag>
            </span>
        </el-form-item>

        <el-form-item :label="lang['html.el-form-item.label.money']">
            <template v-if="info?.CutStatus === 2">
                {{lang['html.el-form-item.label.NoCut']}}
            </template>
            <template v-else>
                {{getUserMoneyTime(info?.money_time)}}{{version == 1 ? info.money_int : info.money_time}}
            </template>
        </el-form-item>

    </el-form>
        <template #footer>
            <el-button type="primary" @click="submit">{{lang['html.el-button.submit']}}</el-button>
            <el-button type="warning" @click="setShow(false)">{{lang['html.el-button.return']}}</el-button>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: "userList-info",
    emits:['updateTable'],
    data(){
        return {
            userId:0,
            info:{},
            userInfoShow:false,
            loading:false,
            version:this.$cookie.getCookie('version'),
            submitData:{},
            lang:this.$lang.getLang('user','userList-info'),
            UserListLang:this.$lang.getLang('user','userList'),
        }
    },
    methods:{
        setShow(boolean,userId){
            this.userInfoShow = boolean;
            this.userId = userId;
            if (boolean){
                this.getUserInfo();
            }
        },
        getUserInfo(){
            this.clearInfo();
            if (this.userId <=0){
                return;
            }
            this.loading = true;
            this.$api.post('User.UserData/getUserData',{userId:this.userId}).then((res)=>{
                this.loading = false;
                let data = this.$helper.checkRes(res);
                this.info = data ? data.data : {};
                this.submitData = {
                    password:this.info.password,
                    nickname:this.info.nickname,
                    contact:this.info.contact,
                    name:this.info.name,
                    status:this.info.status,
                };
            });
        },
        submit(){
            let data = this.submitData;
            //检查是否有修改
            let bool = false;
            for (let dataKey in data) {
                if (data[dataKey] !== this.info[dataKey]){
                    // console.log(dataKey,data[dataKey], this.info[dataKey])
                    bool = true;
                }
            }
            if (!bool){
                this.$alert(this.lang['submit.tips']);
                return;
            }
            this.$message(this.lang['submit.wait']);
            this.loading = true;
            this.$api.post('User.UserData/setUserInfo',{
                userId:this.userId,
                setInfo:data
            }).then((res)=>{
                let data = this.$helper.checkRes(res);
                if(data){
                    this.$alert(this.lang['submit.successful']);
                    // this.getUserInfo();//重新获取用户个人信息
                    //更新成功,通知父组件更新表格
                    this.$emit('updateTable',{});
                }else{
                    let code = this.$helper.getResErrorCode(res);
                    let msg = code == 2 ? this.lang['submit.res.error.2'] : this.lang['submit.res.error.default'];
                    this.$alert(
                        msg + '<br><br>Attch:' + res.data.attch ?? '',
                        'errorCode:' + code,
                        {dangerouslyUseHTMLString:true}
                    );
                }
                this.loading = false;
                console.log('submit',res);
            });
        },
        clearInfo(){
            this.info = {};
            this.submitData = {};
        },
        getUserMoneyTime(time){
            if (this.version == 1){
                return '';
            }
            let obj = new Date(time);
            let int = obj.getTime() - new Date().getTime();
            let res;
            if (int <= 0){
                res = this.UserListLang['getUserMoneyTime.timeout'];
            }else{
                res = parseInt((int/(24*1000*3600))) + this.UserListLang['getUserMoneyTime.day'];
            }
            return '[' + res + ']';
        },
    },
}
</script>

<style scoped>

</style>